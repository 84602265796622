<template>
  <div class="home">
    <HeaderBlue
      anteTitle="L'institut Taxi, votre centre de formation agréé dans les Hauts-de-Seine vous présente ses:"
      title="Formations TAXI et VTC"
      postTitle="Embarquez vers l'excellence au volant : notre formation, votre réussite."
      :backgroundImage="require('@/assets/pictures/header-taxidriver.webp')"
    />
    <RowIcons />
    <UnderRow />
    <IttiHome />
    <HomeFirstSection />
    <HomeService />
    <CallToContact
      title="N'attendez plus !"
      subTitle="Nous sommes à votre entière disposition pour répondre à toutes vos questions et vous accompagner dans vos parcours de formations taxi et vtc. N'hésitez pas à nous contacter dès maintenant et découvrez comment notre équipe peut vous aider à concrétiser vos objectifs professionnels. "
    />
    <HomeDoubleSection />
    <HomeEnd />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderBlue from "@/components/headers/HeaderBlue.vue";
import RowIcons from "@/components/home/RowIcons.vue";
import UnderRow from "@/components/home/UnderRow.vue";
import IttiHome from "@/components/home/IttiHome.vue";
import HomeFirstSection from "@/components/home/HomeFirstSection.vue";
import HomeService from "@/components/home/HomeService.vue";
import CallToContact from "@/components/plugins/CallToContact.vue";
import HomeDoubleSection from "@/components/home/HomeDoubleSection.vue";
import HomeEnd from "@/components/home/HomeEnd.vue";
import { useMeta } from "vue-meta";
export default {
  components: {
    HeaderBlue,
    RowIcons,
    UnderRow,
    IttiHome,
    HomeFirstSection,
    HomeService,
    HomeEnd,
    CallToContact,
    HomeDoubleSection,
  },
  setup() {
    useMeta({
      title: "Formations Taxi et VTC",
      description: [
        {
          content:
            "Préparez votre avenir avec l'Institut Taxi. Formations de qualité pour devenir chauffeur de taxi ou VTC. Stages de récupération de points T3P et services de sensibilisation. Obtenez votre carte professionnelle et améliorez vos compétences. Rejoignez-nous maintenant !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.institut-taxi.fr/home",
        },
      ],
    });
  },

  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      // Vérifier si la page est déjà en haut
      if (window.pageYOffset === 0) {
        return;
      }

      // Faire défiler vers le haut de la page
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
