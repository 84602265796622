<template>
  <div class="container-fluid image-block">
    <div class="image-block__title">
      <h2>Quelles sont les conditions d'inscription à l'examen VTC ?</h2>
    </div>
    <div class="image-block__head">
      <p>
        Le candidat doit être européen ou avoir un titre de séjour lui
        permettant d'exercer en France.
      </p>
      <p>
        Le candidat doit être titulaire d'un permis B depuis au moins trois ans
        et non soumis à une période probatoire.
      </p>
      <p>En capacité physique de devenir chauffeur professionnel</p>
      <p>
        REMARQUE: le casier judiciaire, la visite médicale VTC et le PSC1 VTC ne
        sont pas demandés lors de l'inscription à l'examen VTC">
      </p>
      <p>
        L'institut Taxi c'est 93% de réussite aux examens et plus de 600 avis
        sur google, rejoignez-nous !
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFirstSection",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", "fall");
    });
  },
};
</script>

<style lang="scss" scoped>
.image-block {
  /*display: flex;
    justify-content: space-between;*/
  padding: 90px 10px;
  text-align: center;
  @media screen and (min-width: 992px) {
    padding: 90px 10px;
  }
  &__title {
    color: $color-primary;
    margin-bottom: 40px;
    & h2 {
      font-size: 2.3em;
      @media screen and (min-width: 992px) {
        font-size: 3em;
        margin-top: 10px;
      }
    }
  }
  &__head {
    & p {
      text-align: center;
      margin: 0px 0px 50px;
      @media screen and (min-width: 992px) {
        font-size: 1.3em;
        padding: 0px 30px;
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__image {
    & img {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    }
  }

  &__aside {
    margin: 0px 0px;
    & p {
      text-align: left;
      margin-left: 0.3rem;
      color: $color-text-grey;
      font-size: 1.1em;
      margin-top: 18px;
      @media screen and (min-width: 992px) {
        margin-left: 0rem;
        margin-top: 0px;
        padding: 0rem 2.5rem;
      }
      & span:first-of-type {
        color: $color-primary;
      }
      & span:last-of-type {
        color: $color-secondary;
      }
    }
  }
  .slide-right.active {
    @include slide-right(1.2s);
  }
  .slide-left.active {
    @include slide-left(1.2s);
  }
}
</style>
